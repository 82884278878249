import { Component, Input } from '@angular/core';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';

import { NbSidebarService } from '@nebular/theme';
import { AuthorizationProvider } from '../../../auth/providers/authorization.service';
import { RoleService } from '../../../@core/providers';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent {

  @Input() position = 'normal';

  user: any;

  userMenu = [
    { title: 'Contraseña', link: '/pages/account/change-password', icon: 'fa fa-key'},
    { title: 'Salir', link: '/auth/logout', icon: 'fa fa-sign-out'},
  ];
  constructor(private sidebarService: NbSidebarService,
              private authorizationService: AuthorizationProvider,
              private roleService: RoleService,
              private authService: NbAuthService) {
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload();
          this.disablePasswordChange();
        }
      });
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  disablePasswordChange() {
    if (!this.user.isExternal) {
      const i = this.userMenu.findIndex(item => item.title === 'Contraseña');
      this.userMenu.splice(i, 1);
    }
  }

  goToHome() {
    this.roleService.getMenu().subscribe(menu => {
        this.authorizationService.navigateToPermittedPage(menu);
    });
  }
}
