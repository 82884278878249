import { HalOptions, Page } from './hal.service';
import { HttpParams } from '@angular/common/http';

export class HalHelper {

    static resolveParams(params: HttpParams, options?: HalOptions): HttpParams {
        if (options) {

            if (options.params) {
                for (const param of options.params) {
                    params = params.append(param.key, param.value.toString());
                }
            }

            if (options.size) {
                params = params.append('size', options.size.toString());
            }

            if (options.page) {
                params = params.append('page', (options.page - 1).toString());
            }

            if (options.sort) {
                for (const s of options.sort) {
                    let sortString = '';
                    sortString = s.path ? sortString.concat(s.path) : sortString;
                    sortString = s.order ? sortString.concat(',').concat(s.order) : sortString;
                    params = params.append('sort', sortString);
                }
            }

            if (options.projection) {
                params = params.append('projection', options.projection.toString());
            }
        }
        return params;
    }

    static returnList(result: any, _embedded: string, resource: string, entityType: string) {
        return HalHelper.castEntities(result[_embedded][resource] as any[], entityType)
    }

    static returnPage(result: any, _embedded: string, resource: string, entityType: string): Page {
        const page: Page = {};
        page.results = HalHelper.castEntities(result[_embedded][resource] as any[], entityType);
        page.number = result.page.number + 1;
        page.size = result.page.size;
        page.totalElements = result.page.totalElements;
        page.totalPages = result.page.totalPages;
        return page;
    }

    static castEntity(entity: any, entityType: string) {
        entity.self = entity._links.self.href;
        for (const key of Object.keys(entity._links)) {
            if (key !== 'self' && key !== entityType && Object.keys(entity._links).includes(key)) {
                entity[key] = entity._links[key].href;
            }
        }
        return entity;
    }

    static castEntities(entities: any[], entityType: string) {
        for (let entity of entities) {
            entity = this.castEntity(entity, entityType);
        }
        return entities;
    }
}
