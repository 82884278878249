import { Component, Input, OnInit } from '@angular/core';
import { FileUploaderCustom } from './file-uploader-custom';
import { FileService } from '../../../@core/providers/file.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'ngx-file-drag-drop',
  templateUrl: './file-drag-drop.component.html',
  styleUrls: ['./file-drag-drop.component.scss'],
})
export class FileDragDropComponent implements OnInit {

  @Input()
  public name: string;

  @Input()
  public idx: string;

  @Input()
  public accept: string;

  @Input()
  public multiple: boolean = false;

  @Input()
  public uploader: FileUploaderCustom;

  @Input()
  public showFileList: boolean = true;

  @Input()
  public showButtonRemoveAll: boolean = true;

  @Input()
  public showButtonUpload: boolean = true;

  public hasBaseDropZoneOver: boolean = false;

  constructor(private _fileService: FileService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.uploader.onCompleteAll = () => {
      this.uploader.clearQueue();
      this._fileService.onCompleteAll();
    };
    if (!this.multiple) {
      this.uploader.options.queueLimit = 1;
    }
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  clear() {
    this.uploader.clearQueue();
  }

  upload() {
    this.spinner.show();
    if (this.multiple) {
      this.uploader.uploadAllFiles();
    } else {
      this.uploader.uploadAll();
    }
  }

  remove(index: number, event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.uploader.queue.splice(index, 1);
  }
}
