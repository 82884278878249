import { Injectable } from '@angular/core';
import {
    HttpInterceptor, HttpRequest, HttpHandler, HttpEvent,
    HttpErrorResponse, HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/throw';
import { NgxSpinnerService } from 'ngx-spinner';
import { NbTokenService } from '@nebular/auth';
import { Router } from '@angular/router';
import { ConfirmAccountService } from '../../auth/providers/confirm-account.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class InssideInterceptor implements HttpInterceptor {

    contador: number = 0;

    constructor(private spinner: NgxSpinnerService,
        private _accountService: ConfirmAccountService,
        private _tokenService: NbTokenService,
        private _router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const secureReq = req.clone({
            url: req.url.replace('http://', environment.urlProtocol),
        });

        this.contador++;

        if (this.contador === 1 && (!secureReq.url.includes('/translate')) &&
            (secureReq.params.has('wizard') || !this.isControlAddOrEdit(secureReq.url))) {
            this.spinner.show();
        }
        return next.handle(secureReq)
            .do(event => {
                if (event instanceof HttpResponse) {
                    this.contador--;
                    if (this.contador === 0)
                        this.spinner.hide();
                }
            })
            .catch((err: any) => {
                this.contador--;
                this.spinner.hide();
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 403) {
                        // this._tokenService.clear().subscribe(() => {
                        //     this._router.navigate(['/auth/login']);
                        // });
                    }
                    if (err.status === 409) {
                        return Observable.throw(err);
                    }
                    if (err.status === 412) {
                        this._tokenService.clear().subscribe(() => {
                            this._accountService.validateUser(req.body.userName).subscribe((resp: any) => {
                                this._router.navigate([`/auth/create-password/${resp.key}`]);
                            });
                        });
                    }
                }
                return Observable.throw(err);
            })
    }

    /**
     * Valida si la URL coincide con 'controls' o 'controls/id'
     * @param url
     */
    isControlAddOrEdit(url: string) {
        const patternAddControl = new RegExp('controls$');
        const patternEditControl = new RegExp('controls\/[0-9]+$');
        return patternAddControl.test(url) || patternEditControl.test(url);
    }
}
