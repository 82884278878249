import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HalService } from './hal.service';
import { Observable } from 'rxjs';


@Injectable()
export class ServiceTypeService extends HalService {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'serviceTypes', 'serviceType');
  }
  loadHiredServices(contract: any, inssideServices: any[]) {
    const hiredServices = [];
    if (contract.gapId) {
      hiredServices.push(inssideServices.find((service) =>
        service.code === 'GAP',
      ));
    }
    if (contract.needCertificate) {
      hiredServices.push(inssideServices.find((service) =>
        service.code === 'CERTIFICATION',
      ));
    }
    if (contract.saqId) {
      hiredServices.push(inssideServices.find((service) =>
        service.code === 'SAQ',
      ));
    }
    if (contract.needSaqCertificate) {
      hiredServices.push(inssideServices.find((service) =>
        service.code === 'SAQCERTIFICATION',
      ));
    }
    if (contract.riskHired) {
      hiredServices.push(inssideServices.find((service) =>
        service.code === 'ANALISIS_RIESGO',
      ));
    }
    if (contract.gdprService) {
      hiredServices.push(inssideServices.find((service) =>
        service.code === 'ANALISIS_GDPR',
      ));
    }
    if (contract.vulnerabilitiesHired) {
      hiredServices.push(inssideServices.find((service) =>
        service.code === 'VULNERABILIDADES',
      ));
    }
    if (contract.securityHired) {
      hiredServices.push(inssideServices.find((service) =>
        service.code === 'SECURITY_TEST',
      ));
    }
    if (contract.sidHired) {
      hiredServices.push(inssideServices.find((service) =>
        service.code === 'SID',
      ));
    }
    return Observable.of(hiredServices);
  }

  addServices(contract: any) {
    contract.riskHired = contract.services.some((service) =>
      service.code === 'ANALISIS_RIESGO',
    );
    // TO DO: Modificar cuando modifique el liquibase
    contract.gdprService = contract.services.some((service) =>
      service.code === 'ANALISIS_GPRD',
    );
    contract.vulnerabilitiesHired = contract.services.some((service) =>
      service.code === 'VULNERABILIDADES',
    );
    contract.securityHired = contract.services.some((service) =>
      service.code === 'SECURITY_TEST',
    );
    contract.sidHired = contract.services.some((service) =>
      service.code === 'SID',
    );
    contract.gapService = contract.services.some((service) =>
      service.code === 'GAP' || service.code === 'CERTIFICATION',
    );
    contract.saqService = contract.services.some((service) =>
    service.code === 'SAQ' || service.code === 'SAQCERTIFICATION',
    );
    return Observable.of(contract);
  }
}
