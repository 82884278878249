export class PasswordChangeRequest {

    id: number;
    key: string;
    isChange: boolean;
    isReset: boolean;

    constructor(id: number, key: string, isChange: boolean, isReset: boolean) {
        this.id = id;
        this.key = key;
        this.isChange = isChange;
        this.isReset = isReset;
    }
}
