import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false,
})
export class FilterPipe implements PipeTransform {
  transform(lista: any[], code: string): any {
    if (!code) {
      return;
    }
    return lista.filter(data => data.code === code)[0];
  }
}
