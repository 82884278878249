import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { ChartComponent } from 'angular2-chartjs';

@Component({
  selector: 'ngx-doughnut-chart',
  styleUrls: ['./doughnut.component.scss'],
  templateUrl: './doughnut.component.html',
})
export class DoughnutComponent implements OnInit {

  data = {
    labels: [],
    datasets: [{
      data: [],
      backgroundColor: [],
      borderWidth: 0,
    }],
  };
  options = {
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 72,
    tooltips: {
      enabled: false,
    },
  };

  colors = {
    semaphore: ['#88bf66', '#ffd364', '#ff5252'],
    progress: ['#F300FF', 'rgba(0, 0, 0, 0.2)'],
    simple: ['#88bf66', '#ff5252', 'rgba(51, 107, 255, 1)',
      'rgba(0, 0, 0, 0.2)', '#FF9669', '#C9D1E0', '#A9B0BC',
      '#4084FF', '#81DBFF', '#00F8C3', '#1F1C5D'],
    simple2: ['#88bf66', '#ff5252', 'rgba(0, 0, 0, 0.2)', 'rgba(51, 107, 255, 1)'],
    noData: ['#9c9c9c'],
    detail: ['rgba(255, 82, 82, 1)', 'rgba(136, 191, 102, 1)'],
  }

  @ViewChild(ChartComponent) chart: ChartComponent;

  @Input() type: string = 'simple';

  @Input() tooltip: boolean = false;

  constructor() {

  }

  ngOnInit() {
    if (!this.colors[this.type]) {
      this.type = 'simple';
    }
    this.data.datasets[0].backgroundColor = this.colors[this.type];
    this.options.tooltips.enabled = this.tooltip;
  }

  @Input()
  set results(results: any[]) {
    if (results) {
      this.data.datasets[0].data = [];
      for (const iterator of results) {
        this.data.datasets[0].data.push(iterator.value);
        this.data.labels.push(iterator.name)
      }
      if (this.chart.chart) {
        this.chart.chart.update();
      }
    }
  }
}
