import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthorizationProvider } from '../../auth/providers/authorization.service';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ConfigurationProvider {

    private env = environment;
    private permissions: any;

    constructor(private http: HttpClient) {
    }

    /**
     * Funcion para cargar configuracion de la aplicacion al iniciarse por primera vez
     */
    load() {
        return new Promise((resolve, reject) => {
            this.http
                .get(`${this.env.baseEndpoint}/${this.env.api}/user_permissions`)
                .subscribe((response: any) => {
                    this.permissions = response;
                    resolve(true);
                }, error => resolve(true));
        })
    }

    getPermissions() {
        return this.permissions;
    }

}
