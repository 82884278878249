import { Directive, OnInit, Input, ViewContainerRef, TemplateRef } from '@angular/core';
import { AuthorizationProvider } from '../providers/authorization.service';

@Directive({
    selector: '[ngxAuthorizationShow]',
})
export class AuthorizationShowDirective implements OnInit {

    private _ngxAuthorizationShow: string;
    private _company: string;

    @Input('ngxAuthorizationShow')
    set ngxAuthorizationShow(value: string) {
        this._ngxAuthorizationShow = value;
    }

    @Input('ngxAuthorizationShowCompany')
    set ngxAuthorizationShowCompany(value: string) {
        this._company = value;
    }

    get ngxAuthorizationShow(){
        return this._ngxAuthorizationShow;
    }

    get ngxAuthorizationShowCompany(){
        return this._company;
    }

    constructor(
        private container: ViewContainerRef,
        private template: TemplateRef<any>,
        private auth: AuthorizationProvider,
    ) { }

    ngOnInit() {
        this.auth.isUserPermitted(this.ngxAuthorizationShow, this.ngxAuthorizationShowCompany).subscribe(response => {
            if (response) {
                this.container.createEmbeddedView(this.template);
            }
        });
    }

}
