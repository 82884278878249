import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment'
import { FileUploaderCustom } from '../../@theme/components/upload-file/file-uploader-custom';
import { NgxSpinnerService } from 'ngx-spinner';


@Injectable()
export class FileService {

    @Output()
    completeAll: EventEmitter<any> = new EventEmitter();

    private documentsBaseURL: string = `${environment.baseEndpoint}/${environment.api}/documents/download`;

    constructor(private httpClient: HttpClient,
        private spinner: NgxSpinnerService) { }

    /**
     * Método que realiza la subida de un archivo
     * @param self referencia al objeto
     * @param attribute nombre del atributo donde se guarda el archivo
     * @param uploader contenedor de archivos
     */
    upload(self: string, attribute: string, uploader: FileUploaderCustom): Observable<{}> {
        if (uploader.queue && uploader.queue.length) {
            const URL = `${self}/${attribute}`;
            const files = uploader.getFile();
            uploader.clearQueue();
            return this.httpClient.post<any>(URL, files);
        } else {
            return Observable.of({});
        }
    }

    /**
     * Método que realiza la subida de varios archivos
     * @param self referencia al objeto
     * @param attribute nombre del atributo donde se guardan los archivos
     * @param uploader contenedor de archivos
     */
    uploadAll(self: string, attribute: string, uploader: FileUploaderCustom): Observable<{}> {
        if (uploader.queue && uploader.queue.length) {
            const URL = `${self}/${attribute}`;
            const files = uploader.getFiles();
            uploader.clearQueue();
            return this.httpClient.post<any>(URL, files);
        } else {
            return Observable.of({});
        }
    }

    /**
     * Método que agrega contratos de confidencialidad a un contrato
     * @param contract
     * */
    download(file: any) {
        this.httpClient.post(this.documentsBaseURL, file, { responseType: 'blob' }).subscribe(
            success => {
                const blob = new Blob([success]);
                const blobURL = (window.URL).createObjectURL(blob);
                const element = document.createElement('a');
                element.download = file.name;
                element.href = blobURL;
                element.click();
                element.remove();
            },
            err => {
                alert('Error al descargar el documento ' + err);
            },
        );
    }

    /**
     * Método que lanza el evento de carga correcta de archivos
     */
    onCompleteAll() {
        this.completeAll.emit();
        this.spinner.hide();
    }
}
