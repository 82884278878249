import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule, NbPasswordAuthStrategy, NbAuthJWTToken } from '@nebular/auth';
import { environment } from '../../environments/environment';
import { HttpResponse } from '@angular/common/http';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { AnalyticsService } from './utils/analytics.service';
import { FileService, ServiceTypeService, HalService, RoleService} from './providers/index';
import { EarningService } from './data/earning.service';

export const NB_CORE_PROVIDERS = [
  ...NbAuthModule.forRoot({
    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'username',
        baseEndpoint: environment.baseEndpoint,
        token: {
          class: NbAuthJWTToken,
          getter: (module: string, res: HttpResponse<Object>) => {
            return res.headers.get(environment.header).replace('Bearer ', '');
          },
        },
        login: {
          endpoint: '/login',
        },
      }),
    ], forms: {
      login: {
        redirectDelay: 500,
        strategy: 'username',  // strategy id key.
        redirect: {
          success: '/page/contracts',
          failure: null,
        },
        rememberMe: false,
        forgotPass: true,
        signUp: false,  // whether to show or not the `rememberMe` checkbox
        showMessages: {     // show/not show success/error messages
          success: true,
          error: true,
        },
        socialLinks: {}, // social links at the bottom of a page
      },
      logout: {
        redirectDelay: 0,
      },
    },
  }).providers,
  AnalyticsService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
  providers: [
    RoleService,
    FileService,
    HalService,
    EarningService,
    ServiceTypeService,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
