import { Directive, OnInit, Input, ElementRef, Renderer2 } from '@angular/core';
import { AuthorizationProvider } from '../providers/authorization.service';

@Directive({
    selector: '[ngxAuthorization]',
})
export class AuthorizationDirective implements OnInit {

    @Input() permission: string;

    @Input() company: string;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private auth: AuthorizationProvider,
    ) { }

    ngOnInit() {
        this.auth.isUserPermitted(this.permission, this.company).subscribe(response => {
            this.el.nativeElement.disabled = !response;
            this.renderer[!response ? 'addClass' : 'removeClass'](this.el.nativeElement, 'disabled');
        });
    }

}
