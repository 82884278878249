import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { MenuItem } from '../../@core/providers';
import { Router } from '@angular/router';
import { ConfigurationProvider } from '../../@core/interceptors/configuration.service';

@Injectable({
    providedIn: 'root',
})
export class AuthorizationProvider {

    private _permissions: any;

    get permissions() {
        return this._permissions;
    }

    set permissions(value: any) {
        this._permissions = value;
    }

    constructor(
        private authService: NbAuthService,
        protected router: Router,
        protected configurationProvider: ConfigurationProvider,
    ) {
        this._permissions = configurationProvider.getPermissions();
    }

    getPermissionsByUser(): Observable<any> {
        return new Observable<any>((observer: Observer<any>) => {
            const listPermision = [];
            if (this.permissions) {
                this.permissions.forEach(company => {
                    company.authorities.forEach(permission => {
                        if (!listPermision.includes(permission)) {
                            listPermision.push(permission);
                        }
                    });
                });
                observer.next(listPermision);
            }
            observer.complete();
        });
    }

    isUserPermitted(permission: string, companyId?: string): Observable<any> {
        return new Observable<any>((observer: Observer<any>) => {
            this.getPermissionsByUser().subscribe(response => {
                if (permission && companyId) {
                    const company = this.permissions.find(item => item.company === parseInt(companyId, 0));
                    observer.next(permission && company && company.authorities.includes(permission));
                } else {

                    observer.next(permission && response.includes(permission));
                }
                observer.complete();
            });
        });
    }

    navigateToPermittedPage(menu: MenuItem[]) {
        for (const item of menu) {
            if (!item.hidden) {
                if (!item.children) {
                    return this.router.navigate([item.link]);
                } else {
                    item.children.forEach(child => {
                        if (!child.hidden) {
                            return this.router.navigate([child.link]);
                        }
                    });
                }
            }
        }
    }

    isExternalUser(): Observable<any> {
        return new Observable<any>((observer: Observer<any>) => {
            this.authService.getToken().subscribe((token: NbAuthJWTToken) => {
                // TODO MAPEAR
                observer.next(token.getPayload().isExternal ? true : false);
                observer.complete();
            });
        });
    }

    getUserPermissions(): Observable<any> {
        return new Observable<any>((observer: Observer<any>) => {
            observer.next(this.permissions);
            observer.complete();
        });
    }
}
