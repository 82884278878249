/**
 * Definicion de las variables de entorno para el ambiente de produccion en AWS
 */
export const environment = {
    production: false,
    name: 'qa',
    baseEndpoint: 'https://issback.insside.net',
    api: 'api',
    header: 'Authorization',
    urlProtocol: 'https://',
    captchaSiteKey: '6LfiksMUAAAAAAbWhgvsCxa7bta8UXdRor0LYskU',
  };