import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[ngxValidateEqual]',
  providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => EqualValidator), multi: true },
  ],
})
export class EqualValidator implements Validator {
  constructor(@Attribute('ngxValidateEqual') public ngxValidateEqual: string) { }

  validate(c: AbstractControl): { [key: string]: any } {
    // self value (e.g. retype password)
    const v = c.value;

    // control value (e.g. password)
    const e = c.root.get(this.ngxValidateEqual);

    // value not equal
    if (e) {
      if ((v !== e.value) && (v !== '') && (e.value !== '')) {
        return {
          ngxValidateEqual: false,
        }
      }

      if (e.errors && !e.errors.required) {
         e.setErrors(null);
      }
    }

    return null;
  }
}
