import { Component } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { ConfirmAccountService } from '../../providers/confirm-account.service';
import { PasswordChangeRequest } from './password-change-request.model';

@Component({
  selector: 'ngx-create-password',
  templateUrl: './create-password.component.html',
})
export class CreatePasswordComponent {
  public sucess: boolean = false;
  public resendMailConf: boolean = false;
  public checkUpperLetters: boolean;
  public checkLowerLetters: boolean;
  public checkSpecialCharacters: boolean;
  public checkN: boolean;
  public user: any = {};
  public lowerLetters = /[a-z]+/;
  public upperLetters = /[A-Z]+/;
  public specialCharacters = /[!@#$%^&*(),.?":{}|<>]+/;
  public numbers = /[0-9]+/;
  public lenghtPass: boolean;
  public key: string;
  public usedPassword: string = null;
  public id: number;
  public error: any = null;
  public isChange: boolean = false;
  public isReset: boolean = false;
  public action: string;
  public returnLogin: string = 'Volver al login';
  private inputPassword: boolean = true;
  private eyeIcon: string = 'fa fa-eye-slash';

  constructor(
    private _toasterService: ToasterService,
    private _router: Router,
    private _activateRoute: ActivatedRoute,
    private _confirmAccountService: ConfirmAccountService) {

    this._activateRoute.params.subscribe(params => {
      const decoded = JSON.parse(atob(params['key']));
      this.key = decoded.key;
      this.id = decoded.id;
      this.isChange = decoded['isChange'] ? true : false;
      this.isReset = decoded['isReset'] ? true : false;
      if (this.isChange || this.isReset || !this.key || !this.id) {
        this._confirmAccountService.validateKey(
          new PasswordChangeRequest(this.id, this.key, this.isChange, this.isReset)).subscribe((resp) => {
          },
            (error) => {
              this.error = error.error;
              this.action = this.error.code === 5 ? 'Reenviar email' : this.returnLogin;
            });
      }
      else if (!this.isReset) {
        this._confirmAccountService.validateKey(
          new PasswordChangeRequest(this.id, this.key, this.isChange, this.isReset)).subscribe((resp) => {
          },
            (error) => {
              this.error = error.error;
              this.action = this.error.code === 5 ? 'Reenviar email' : this.returnLogin;
            });
      }
    },
    );
  }

  createPassword(form: NgModel) {
    if (form.valid) {
      if (this.checkLowerLetters && this.checkN && this.checkUpperLetters && this.checkSpecialCharacters) {
        if (!this.isChange && !this.isReset) {
          this._confirmAccountService.createPassword(this.user.newPassword, this.key, this.id).subscribe(() => {
            this._router.navigate(['/auth/login']);
            this.popToast('success', 'Exito', 'Se creó la contraseña correctamente');
            this.sucess = true;
          })
        } else {
          this._confirmAccountService.updatePassword(
            { password: this.user.newPassword, key: this.key, id: this.id, isReset: this.isReset }).subscribe(() => {
              this._router.navigate(['/auth/login']);
              this.popToast('success', 'Exito', 'Se cambio la contraseña correctamente');
              this.sucess = true;
            }, (error) => {
              this.usedPassword = error.error.description;
            })
        }
      }
    }
  }

  checkPassword(newPassword: any) {
    this.checkLowerLetters = this.lowerLetters.test(newPassword) ? true : false;
    this.checkUpperLetters = this.upperLetters.test(newPassword) ? true : false;
    this.checkN = this.numbers.test(newPassword) ? true : false;
    this.lenghtPass = newPassword.length >= 8 ? true : false;
    this.checkSpecialCharacters = this.specialCharacters.test(newPassword) ? true : false;
  }

  resendMail() {
    this._confirmAccountService.resendMail(this.id).subscribe(() => {
      this.resendMailConf = true;
      this.action = 'Volver al login';
    }, () => {
    })
  }

  continue() {
    this._router.navigate(['/auth/login']);
  }

  popToast(type: string, title: string, body: string) {
    this._toasterService.pop(type, title, body);
  }

  makeAction() {
    if (this.action === this.returnLogin) {
      this.continue();
    } else
      this.resendMailConf ? this.continue() : this.resendMail();
  }

  showPassword = () => {
    if (this.inputPassword === true) {
      this.inputPassword = false;
      this.eyeIcon = 'fa fa-eye';
    } else {
      this.inputPassword = true;
      this.eyeIcon = 'fa fa-eye-slash';
    }
  };
}
