import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[ngxNumberOnly]',
})
export class NumberOnlyDirective {

    @Input() maxSize: number;

    // Allow decimal numbers and negative values
    private regex: RegExp = new RegExp(/^-?[0-9]+$/g);
    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];

    constructor(private el: ElementRef) {
    }
    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        const current: string = this.el.nativeElement.value;
        if (this.maxSize && current.length >= this.maxSize) {
            return;
        }
        const next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }
}
