import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { RoleService } from '../../@core/providers/role.service';
import { Observable } from 'rxjs';
import { AuthorizationProvider } from './authorization.service';


@Injectable()
export class RoleGuard implements CanActivate {

  constructor(private authService: NbAuthService,
    private roleService: RoleService,
    private router: Router,
    private auth: AuthorizationProvider) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const permimssion: string = route.data.permission;
    return this.authService.isAuthenticated().flatMap(authenticated => {
      if (!authenticated) {
        this.router.navigate(['auth/login']);
        return Observable.of(false);
      } else {
        return this.auth.isUserPermitted(permimssion).flatMap(response => {
          if (!response && typeof response !== 'undefined') {
            this.roleService.getMenu().subscribe(menu => {
            //   this.auth.navigateToPermittedPage(menu);
                this.router.navigate(['pages/not-authorized']);
            });
          }
          return Observable.of((typeof response === 'undefined') ? true : response);
        });
      }
    })
  }
}
