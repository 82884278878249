import { Component, Input } from '@angular/core';
@Component({
    selector: 'ngx-stepper',
    styleUrls: ['./stepper.component.scss'],
    templateUrl: './stepper.component.html',
})
export class StepperComponent {

    @Input() state: string;

    @Input() level: number;

    @Input() finalCertification: boolean;

    @Input() saqFinalCertification: boolean;

    percentage: number = 0;

    constructor() {}

    @Input()
    set progress(progress: number) {
        // If 'this.level === 1' is a ROC Contract
        if (this.level === 1) {
            if (progress < 100) {
                this.percentage = 25;
            } else if (this.state === 'ACTIVO') {
                this.percentage = 50;
            } else
                if (this.state === 'TRADUCIENDO'
                    || (this.state === 'COMPLETO'
                        && (!this.saqFinalCertification || !this.finalCertification)
                    )) {
                    this.percentage = 75;
                } else { this.percentage = 100; }
        } else {
            if (progress < 100 && (this.state === 'ACTIVO' || this.state === null)) {
                this.percentage = 33;
            } else if (this.state !== 'FINALIZADO' && (!this.saqFinalCertification || !this.finalCertification)) {
                this.percentage = 66;
            }
            else { this.percentage = 100; }
        }
    }
}
