import { Component } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ConfirmAccountService } from '../providers/confirm-account.service';

@Component({
  selector: 'ngx-reset-password-request',
  templateUrl: './reset-password-request.component.html',
})
export class ResetPasswordRequestComponent {
  public resetAccount: any = {};
  public view: boolean = false;
  public key: string;
  public done: boolean = false;

  constructor(private _confimAccountService: ConfirmAccountService) { }

  requestResetPassword(form: NgModel) {
    if (form.valid) {
      this._confimAccountService.requestResetPassword(this.resetAccount).subscribe(() => {
        this.done = true;
      }, (error) => { console.error(error) });
    }
  }

}

