import { Component, Input, ViewChild } from '@angular/core';
import { ChartComponent } from 'angular2-chartjs';

@Component({
  selector: 'ngx-bar-chart',
  styleUrls: ['./bar.component.scss'],
  templateUrl: './bar.component.html',
})
export class BarComponent {

  data = {
    labels: [],
    datasets: [{
      data: [],
      backgroundColor:  [],
    }],
  };
  options = {
    responsive: true,
    tooltips: {
      enabled: true,
    },
    legend: {
        display: false,
    },
    scales: {
      yAxes: [{
        ticks: {
          fontColor: '#9f9fa9',
        },
      }],
      xAxes: [{
        ticks: {
          fontColor: '#9f9fa9',
        },
      }],
    },
  };

  @ViewChild(ChartComponent) chart: ChartComponent;
  @Input() type: string = 'simple';

  constructor() {

  }

  @Input()
  set colors(colors: any[]){
    this.data.datasets[0].backgroundColor = colors;
  }

  @Input()
  set results(results: any[]) {
    if (results) {
      this.data.datasets[0].data = [];
      this.data.labels = [];
      for (const iterator of results) {
        this.data.datasets[0].data.push(iterator.value);
        this.data.labels.push(iterator.name)
      }
      if (this.chart.chart) {
        this.chart.chart.update();
      }
    }
  }
}
