import { Component, forwardRef } from '@angular/core';
import {
  NgbDateParserFormatter, NgbDatepickerI18n,
  NgbDatepickerConfig, NgbDateAdapter,
} from '@ng-bootstrap/ng-bootstrap';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { DateParserFormatter } from './date-parser-formatter';
import { NgbMomentjsAdapter } from './date-adapter';
import { CustomDatepickerI18n, I18n } from './datepickerI18n.service';

const noop = () => {
};

@Component({
  selector: 'ngx-datepicker',
  styleUrls: ['./datepicker.component.scss'],
  templateUrl: './datepicker.component.html',
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    NgbDatepickerConfig,
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatepickerComponent), multi: true },
    { provide: NgbDateAdapter, useClass: NgbMomentjsAdapter },
    { provide: NgbDateParserFormatter, useClass: DateParserFormatter }],
})
export class DatepickerComponent implements ControlValueAccessor {

  constructor(config: NgbDatepickerConfig) {
    // customize default values of datepickers used by this component tree
    config.minDate = { year: 2000, month: 1, day: 1 };
    config.maxDate = { year: 2100, month: 12, day: 31 };
    // week start sunday
    config.firstDayOfWeek = 7;

    config.navigation = 'select';
  }

  // The internal data model
  private innerValue: any = '';

  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // get accessor
  get value(): any {
    return this.innerValue;
  };

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  // Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }

  // From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
