import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ThemeModule } from '../@theme/theme.module';
import { AuthRoutingModule, routedComponents } from './auth-routing.module';
import { AuthorizationDirective, AuthorizationShowDirective } from './directives';
import { ToasterModule, ToasterService } from 'angular2-toaster';
import { ConfirmAccountService } from './providers/confirm-account.service';
import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import {NbSpinnerModule} from '@nebular/theme';

const globalSettings: RecaptchaSettings = { siteKey: '6LdNk8MUAAAAAHbcXe6qe9KP6C5kpyCx15xprvCT'};

@NgModule({
    imports: [
        ThemeModule,
        AuthRoutingModule,
        ToasterModule.forRoot(),
        RecaptchaModule.forRoot(),
        NbSpinnerModule,
    ],
  declarations: [
    ...routedComponents,
    AuthorizationDirective,
    AuthorizationShowDirective,
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: globalSettings,
    },
    ToasterService,
    ConfirmAccountService,
  ],
  exports: [
    AuthorizationDirective,
    AuthorizationShowDirective,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AuthModule { }
