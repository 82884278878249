import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ngx-gauge-chart',
  template: `
    <chart [type]="type" [data]="data" [options]="options"></chart>
  `,
})
export class GaugeComponent implements OnInit {

  type = 'doughnut';
  data = {
    datasets: [],
  };

  colors = ['#88bf66', '#ffd364', '#ff5252', 'rgba(0, 0, 0, 0.2)'];

  @Input() value: number;

  options = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 65,
    tooltips: {
      enabled: false,
    },
    rotation: 1 * Math.PI,
    circumference: 1 * Math.PI,
  };

  constructor() {

  }

  ngOnInit() {
    const graphColors = [this.colors[0], this.colors[3]];
    if (this.value > 66) {
      graphColors[0] = this.colors[2]
    } else if (this.value > 33) {
      graphColors[0] = this.colors[1]
    }
    const graphData: number[] = [this.value, (100 - this.value)];
    this.data.datasets.push({ data: graphData, backgroundColor: graphColors });
  }
}
