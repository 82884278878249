import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NgbMomentjsAdapter extends NgbDateAdapter<string> {

    fromModel(date: string): NgbDateStruct {
        if (!date) {
            return null
        }

        const dateArray: string[] = date.substring(0, 10).split('-');
        if (!Number(dateArray[0]) || !Number(dateArray[1]) || !Number(dateArray[2])) {
            console.error('NgbMomentjsAdapter: Error al deserializar la fecha: ' + date);
            return null;
        }

        return {
            year: Number(dateArray[0]),
            month: Number(dateArray[1]),
            day: Number(dateArray[2]),
        };
    }

    toModel(date: NgbDateStruct): string {
        if (!date || !Number(date.day) || !Number(date.day) || !Number(date.day)) {
           return null;
        }
        return `${date.year}-${('0' + date.month).slice(-2)}-${('0' + date.day).slice(-2)}`;
    }
}
