import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => {
};

@Component({
  selector: 'ngx-form-upload',
  templateUrl: './form-upload.component.html',
  styleUrls: ['./form-upload.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormUploadComponent),
    multi: true,
  }],
})
export class FormUploadComponent {

  @Input()
  public name: string;

  @Input()
  public idx: string;

  @Input()
  public accept: string;

  @Input()
  public multiple: boolean;

  private innerValue: any;

  constructor() { }

  get value(): FileList {
    return this.innerValue;
  };

  isMultiple(): boolean {
    return this.multiple;
  };

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: FileList) => void = noop;

  set value(v: FileList) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: FileList) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  selectFiles(event) {
    this.value = event.target.files;
  }

  selectFile(event) {
    this.value = event.target.files.item(0);
  }
}
