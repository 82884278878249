import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment'
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { HalHelper } from './hal.helper';
import 'rxjs/add/operator/mergeMap';
import { of } from 'rxjs/observable/of'
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';

export type SortOrder = 'DESC' | 'ASC';
export interface Sort { path: string; order: SortOrder; }
export interface HalParam { key: string, value: string | number | boolean| number[] };
export interface HalOptions {
  notPaged?: boolean,
  page?: number,
  size?: number,
  projection?: string,
  sort?: Sort[],
  params?: HalParam[]
};
export interface Page { results?: any[], number?: number, size?: number, totalElements?: number, totalPages?: number }

@Injectable()
export class HalService {

  private baseUrl: string;

  constructor(private _httpClient: HttpClient,
              private resource: string,
              private entityType: string,
              private _embedded?: string) {
    this.baseUrl = `${environment.baseEndpoint}/${environment.api}/${this.resource}`;
    if (isNullOrUndefined(this._embedded)) {
      this._embedded = '_embedded';
    }
  }

  /**
   * Método que realiza la busqueda por defecto de entidades
   * @param options parámetros de la consulta
   */
  public getAll<T>(options?: HalOptions): any {
    let params = HalHelper.resolveParams(new HttpParams(), options);
    // Se realiza primer invocación para recupepar información
    return this._httpClient.get<T>(this.baseUrl, { params: params })
      .flatMap((result: any) => {
        // Valida si se quiere paginar o no
        if (options && options.notPaged) {
          // No requiere paginado
          if ((result.page.totalElements > result.page.size)) {
            // Se invoca nuevamente la api para traer los elementos faltantes
            options.size = result.page.totalElements;
            params = HalHelper.resolveParams(new HttpParams(), options);
            return this._httpClient.get<T>(this.baseUrl, { params: params })
              .map((res: any) => {
                return HalHelper.returnList(res, this._embedded, this.resource, this.entityType)
              });
          } else {
            // Se retorla listado de elementos
            return of(HalHelper.returnList(result, this._embedded, this.resource, this.entityType));
          }
        } else {
          // Retorna pagina de la entidad consultada
          return of(HalHelper.returnPage(result, this._embedded, this.resource, this.entityType));
        }
      });
  }

  /**
   * Método que retorna una entidad
   * @param id identificador de la entidad
   * @param projection opcional, si se quiere obtener una vista modificada de la entidad
   */
  public get<T>(id: string, projection?: string) {
    const params = HalHelper.resolveParams(new HttpParams(), { projection: projection });
    const uri = `${this.baseUrl}/${id}`;
    return this._httpClient.get<T>(uri, { params: params }).map(
      (res: any) => {
        return HalHelper.castEntity(res, this.entityType);
      })
  }

  /**
   * Método que retorna una entidad
   * @param resourceLink link de la entidad
   * @param projection opcional, si se quiere obtener una vista modificada de la entidad
   */
  public getBySelfLink<T>(resourceLink: string, projection?: string): Observable<T> {
    const params = HalHelper.resolveParams(new HttpParams(), { projection: projection });
    return this._httpClient.get<T>(resourceLink, { params: params }).map(
      (res: any) => {
        return HalHelper.castEntity(res, this.entityType);
      })
  }

  /**
   * Método para realizar la creación de una entidad
   * @param entity
   * */
  public create<T>(entity: any, options?: HalOptions) {
    const body = JSON.stringify(entity);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = HalHelper.resolveParams(new HttpParams(), options);
    return this._httpClient.post<T>(this.baseUrl, body, { headers, params }).map(
      res => {
        return HalHelper.castEntity(res, this.entityType);
      })
  }

  /**
   * Método para realizar la actualización de una entidad
   * @param entity
   * */
  public update<T>(id: string, entity: any) {
    const body = JSON.stringify(entity);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const uri = `${this.baseUrl}/${id}`;
    return this._httpClient.put<T>(uri, body, { headers }).map(
      res => {
        return HalHelper.castEntity(res, this.entityType);
      })
  }

  /**
   * Método para realizar la actualización parcial de una entidad
   * @param entity
   * */
  public patch<T>(id: string, entity: any) {
    const body = JSON.stringify(entity);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const uri = `${this.baseUrl}/${id}`;
    return this._httpClient.patch<T>(uri, body, { headers }).map(
      res => {
        return HalHelper.castEntity(res, this.entityType);
      })
  }

  /**
   * Método que permite ejecutar los servicios definidos
   * @param query path del servicio
   * @param options parámetros de la consulta
   * */
  public searchAll<T>(query: string, options?: HalOptions) {
    const uri = `${this.baseUrl}/search/${query}`;
    let params = HalHelper.resolveParams(new HttpParams(), options);
    return this._httpClient.get<T>(uri, { params: params })
      .flatMap((result: any) => {
        // Valida si se quiere paginar o no
        if (options && options.notPaged) {
          // No requiere paginado
          if ((result.page.totalElements > result.page.size)) {
            // Se invoca nuevamente la api para traer los elementos faltantes
            options.size = result.page.totalElements;
            params = HalHelper.resolveParams(new HttpParams(), options);
            return this._httpClient.get<T>(uri, { params: params })
              .map((res: any) => {
                return HalHelper.returnList(res, this._embedded, this.resource, this.entityType)
              });
          } else {
            // Se retorna listado de elementos
            return of(HalHelper.returnList(result, this._embedded, this.resource, this.entityType));
          }
        } else {
          // Retorna pagina de la entidad consultada
          return of(HalHelper.returnPage(result, this._embedded, this.resource, this.entityType));
        }
      });
  }

  /**
   * Método que permite ejecutar los servicios definidos
   * @param query path del servicio
   * @param options parámetros de la consulta
   * */
  public searchOne<T>(query: string, options?: HalOptions) {
    const uri = `${this.baseUrl}/search/${query}`;
    const params = HalHelper.resolveParams(new HttpParams(), options);
    return this._httpClient.get<T>(uri, { params: params }).map(
      (res: any) => {
        return HalHelper.castEntity(res, this.entityType);
      })
  }

  public getBaseUrl() {
    return this.baseUrl;
  }

  public getEntityType() {
    return this.entityType;
  }
  
  public getAllByQuery<T>(query: string, options?: HalOptions): any {
      const uri = `${this.baseUrl}/${query}`
      let params = HalHelper.resolveParams(new HttpParams(), options);
      // Se realiza primer invocación para recupepar información
      return this._httpClient.get<T>(uri, { params: params })
        .flatMap((result: any) => {
          // Valida si se quiere paginar o no
          if (options && options.notPaged) {
            // No requiere paginado
            if ((result.page.totalElements > result.page.size)) {
              // Se invoca nuevamente la api para traer los elementos faltantes
              options.size = result.page.totalElements;
              params = HalHelper.resolveParams(new HttpParams(), options);
              return this._httpClient.get<T>(this.baseUrl, { params: params })
                .map((res: any) => {
                  return HalHelper.returnList(res, this._embedded, this.resource, this.entityType)
                });
            } else {
              // Se retorla listado de elementos
              return of(HalHelper.returnList(result, this._embedded, this.resource, this.entityType));
            }
          } else {
            // Retorna pagina de la entidad consultada
            return of(HalHelper.returnPage(result, this._embedded, this.resource, this.entityType));
          }
        });
    }
}
