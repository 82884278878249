import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { PasswordChangeRequest } from '../components/create-password/password-change-request.model';

@Injectable()
export class ConfirmAccountService {

  constructor(
    private httpClient: HttpClient) { }

  createPassword(newPassword: string, key: string, id: number) {
    return this.httpClient.post(`${environment.baseEndpoint}/api/account/create-password/${id}/${key}`, { newPassword });
  }

  updatePassword(passwordUpdateRequest: any) {
    return this.httpClient.post(`${environment.baseEndpoint}/api/account/update-password`, passwordUpdateRequest);
  }

  validateKey(passwordChangeRequest: PasswordChangeRequest) {
    return this.httpClient.post(`${environment.baseEndpoint}/api/account/validate-key`, passwordChangeRequest);
  }

  checkKey(key: string, id: number) {
    return this.httpClient.get(`${environment.baseEndpoint}/api/account/check-key/${key}/${id}`);
  }

  resendMail(id: number) {
    return this.httpClient.get(`${environment.baseEndpoint}/api/account/resend-mail/${id}`);
  }

  validateUser(username: string) {
    return this.httpClient.get(`${environment.baseEndpoint}/api/account/validate-user/${username}`);
  }

  requestResetPassword(resetRequest: any) {
    return this.httpClient.post(`${environment.baseEndpoint}/api/account/reset-password/init`, resetRequest);
  }

  resetPassword(keyAndPassword: any) {
    return this.httpClient.post(`${environment.baseEndpoint}/api/account/reset-password`, keyAndPassword);
  }

  validateResetKey(resetKey: number) {
    return this.httpClient.post(`${environment.baseEndpoint}/api/account/validate-resetKey`, resetKey);
  }

  checkCaptcha(tCaptcha: any) {
    return this.httpClient.get<any>(`${environment.baseEndpoint}/api/validate-captcha/${tCaptcha}`);
  }

}
