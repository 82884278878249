import {Component, ElementRef, Input, Renderer} from '@angular/core';

@Component({
  selector: 'ngx-password-strength-bar',
  template: `
    <div class="strength">

      <ul class="strengthBar">
        <li class="point"></li>
        <li class="point"></li>
        <li class="point"></li>
        <li class="point"></li>
        <li class="point"></li>

      </ul>
      <small>{{statusPassword}}</small>
    </div>`,
  styleUrls: ['./password-strength-bar.scss'],
})
export class PasswordStrengthBarComponent {
  colors = ['#333399', '#333399', '#333399', '#333399', '#333399', '#333399', '#333399'];
  statusPassword: string = 'Insegura';
  constructor(private renderer: Renderer, private elementRef: ElementRef) {
  }

  measureStrength(p: string): number {
    let force = 0;
    const regex = /[$-/:-?{-~!"^_`\[\]]/g;
    // const symbols = regex.test(p);
    const lowerLetters = /[a-z]+/.test(p);
    const upperLetters = /[A-Z]+/.test(p);
    const numbers = /[0-9]+/.test(p);
    const charCount = /^\w{8,}$/.test(p); // allow words where has minimum 8 characters
    const specialChars = /[!@#$%^&*(),.?":{}|<>]+/.test(p)

    const flags = [lowerLetters, upperLetters, numbers, charCount,specialChars];
    const passedMatches = flags.filter((isMatchedFlag: boolean) => {
      return isMatchedFlag === true;
    }).length;

    force += 2 * p.length + (p.length >= 10 ? 1 : 0);
    force += passedMatches * 10;

    // penalty (short password)
    force = p.length <= 6 ? Math.min(force, 10) : force;

    // penalty (poor variety of characters)
    force = passedMatches === 1 ? Math.min(force, 10) : force;
    force = passedMatches === 2 ? Math.min(force, 20) : force;
    force = passedMatches === 3 ? Math.min(force, 40) : force;
    return force;
  }

  getColor(s: number): any {
    let idx = 0;
    if (s <= 10) {
      idx = 0;
      this.statusPassword = 'Insegura';
    } else if (s <= 20) {
      idx = 1;
      this.statusPassword = 'Insegura';
    } else if (s <= 30) {
      idx = 2;
      this.statusPassword = 'Insegura';
    } else if (s <= 40) {
      idx = 3;
      this.statusPassword = 'Insegura';
    } else {
      idx = 4;
      this.statusPassword = 'Segura';
    }
    return {idx: idx + 1, col: this.colors[idx]};
  }

  @Input()
  set passwordToCheck(password: string) {
    if (password) {
      const c = this.getColor(this.measureStrength(password));
      const element = this.elementRef.nativeElement;
      if (element.className) {
        this.renderer.setElementClass(element, element.className, false);
      }
      const lis = element.getElementsByTagName('li');
      for (let i = 0; i < lis.length; i++) {
        if (i < c.idx) {
          this.renderer.setElementStyle(lis[i], 'backgroundColor', c.col);
        } else {
          this.renderer.setElementStyle(lis[i], 'backgroundColor', '#DDD');
        }
      }
    }
  }
}
