import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { NbTokenService } from '@nebular/auth';
import { NbMenuItem } from '@nebular/theme';
import { AuthorizationProvider } from '../../auth/providers/authorization.service';
import { Observer } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class RoleService {
    private env = environment;
    private user: any;
    private permissions: any;
    private menu: MenuItem[] = [
        {
            title: 'Dashboard',
            icon: 'nb-home',
            link: '/pages/service/dashboard',
            home: true,
            external: true,
        },
        {
            title: 'Security Suite',
            icon: 'nb-keypad',
            link: '/pages/service/suite',
            external: true,
        },
        {
            title: 'Contratos',
            icon: 'nb-compose',
            link: '/pages/contracts/list',
            home: true,
            external: false,
            permission: 'CONTRACT_LIST',
        },
        {
            title: 'Servicios',
            icon: 'nb-grid-a',
            external: false,
            children: [
                {
                    title: 'ANALISIS DE RIESGOS',
                    link: '/pages/risk-analysis/redirect',
                    permission: 'RSK_SRV',
                },
            ],
        },
        {
            title: 'Administración',
            icon: 'nb-gear',
            external: false,
            children: [
                {
                    title: 'Empresas',
                    link: '/pages/companies/list',
                    permission: 'COMPANY_LIST',
                },
                {
                    title: 'Usuarios',
                    link: '/pages/users/list',
                    permission: 'USER_LIST',
                },
                {
                    title: 'Roles',
                    link: '/pages/permission/list',
                    permission: 'ACCESS_CONTROL',
                },
                {
                    title: 'Audit Trail',
                    link: '/pages/audit/list',
                    permission: 'ACCESS_CONTROL',
                },
            ],
        },
        {
            title: 'Reportes',
            icon: 'nb-tables',
            external: false,
            children: [
                {
                    title: 'Usuarios Inactivos',
                    link: '/pages/reports/list/inactive-users',
                    permission: 'USER_LIST',
                },
                {
                    title: 'Usuarios Bloqueados',
                    link: '/pages/reports/list/blocked-users',
                    permission: 'USER_LIST',
                },
                {
                    title: 'Expiracion Contraseña',
                    link: '/pages/reports/list/expired-password',
                    permission: 'USER_LIST',
                },
            ],
        },
    ];

    constructor(private auth: AuthorizationProvider,
        private _tokenService: NbTokenService, private _router: Router, private http: HttpClient) {
        this.auth.getUserPermissions().subscribe(permissions => {
            this.permissions = permissions;
        });
    }

    getMenu(): Observable<NbMenuItem[]> {
        return new Observable<any>((observer: Observer<any>) => {
            this.auth.isExternalUser().subscribe(external => {
                !external ? this.menu.map(item => {
                    this.auth.getPermissionsByUser().subscribe(permissions => {
                        if (item.children) {
                            let show = false;
                            item.children.map(child => {
                                !permissions.includes(child.permission) ? child.hidden = true : show = true;
                            });
                            item.hidden = !show;
                        } else {
                            item.hidden = !permissions.includes(item.permission);
                        }
                    });
                }) : this.menu.map(item => item.hidden = !item.external);
                observer.next(this.menu);
                observer.complete();
            });
        })
    }

    getUserPermissions() {
        return this.http.get(`${this.env.baseEndpoint}/${this.env.api}/user_permissions`);
    }

    timeInactive() {
        let t;
        const router = this._router;
        const token = this._tokenService;
        window.onload = resetTimer;

        window.onclick = resetTimer;
        window.onkeypress = resetTimer;
        window.onmousedown = resetTimer;
        window.onmousemove = resetTimer;
        window.ontouchstart = resetTimer;
        window.onscroll = resetTimer;
        window.addEventListener('scroll', resetTimer, true);

        function logout() {
            token.clear().subscribe(() => {
                router.navigate(['/auth/login']);
            });
        }
        function resetTimer() {
            clearTimeout(t);
            t = setTimeout(logout, 900000)
        }
    }

    getUserRole(): string {
        return this.user.role;
    }

    getUserCompany(): number {
        return this.user.companyId;
    }

    getUserCompanies() {
        const companies = [];
        this.auth.getUserPermissions().subscribe(permissions => {
            this.permissions = permissions;
            if (this.permissions) {
                this.permissions.forEach(item => {
                    companies.push(item.company);
                });
            }
        });
        return companies;
    }
}


export class MenuItem extends NbMenuItem {

    permission?: string;
    children?: MenuItem[];
    external?: Boolean;

    constructor() {
        super();
    }

}
