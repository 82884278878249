import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'ngx-modal-confirm',
    templateUrl: './modal-confirm.component.html',
})
export class ModalConfirmComponent {
    @Input() title;
    @Input() message;
    @Input() noInfo;

    constructor(public activeModal: NgbActiveModal) { }

    closeModal() {
        this.activeModal.close();
    }

}
